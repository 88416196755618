<template>
  <!-- 账号列表 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button
        v-if="hasPermiss('zhqx_zhangHaoGuanLi:add')"
        class="itemBtn btnColor"
        @click="addMember"
        >新建</el-button
      >
      <!-- <el-button  v-if="hasPermiss('zhqx_zhangHaoGuanLi:del')" class="itemBtn btnColor">删除</el-button> -->
      <el-button
        v-if="hasPermiss('zhqx_zhangHaoGuanLi:update')"
        class="itemBtn btnColor"
        @click="modifybut()"
        >修改</el-button
      >
      <el-button
        v-if="hasPermiss('zhqx_zhangHaoGuanLi:close')"
        class="itemBtn btnColor"
        @click="Enable(0, '停用')"
        >停用</el-button
      >
      <el-button
        v-if="hasPermiss('zhqx_zhangHaoGuanLi:open')"
        class="itemBtn btnColor"
        @click="Enable(1, '启用')"
        >启用</el-button
      >
      <el-button
        v-if="
          hasPermiss('zhqx_zhangHaoGuanLi:relatedRole') &&
          hasPermiss('zhqx_jsgl:sava:menu')
        "
        class="itemBtn btnColor"
        @click="jurisdiction"
        >角色分配</el-button
      >
      <el-button
        v-if="
          hasPermiss('zhqx_zhangHaoGuanLi:relatedRole') &&
          hasPermiss('zhqx_jsgl:sava:menu')
        "
        class="itemBtn btnColor"
        @click="jurisdiction_qx"
        >权限分配</el-button
      >
      <!-- 新建修改 -->
      <el-dialog
        :title="title"
        :visible.sync="NewWarehouse"
        @close="WarehouseClose"
      >
        <div class="newForm">
          <el-form
            label-width="120px"
            :rules="rules"
            status-icon
            ref="Warehousearray"
            :model="Warehousearray"
          >
            <div class="queryItem1">
              <el-form-item label="账号:" prop="username">
                <el-input
                  :disabled="title == '修改'"
                  auto-complete="off"
                  v-model="Warehousearray.username"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1" v-if="title !== '修改' || isChangePas">
              <el-form-item label="密码:">
                <el-input
                  auto-complete="news-password"
                  v-model="Warehousearray.password"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1" v-if="title !== '修改' || isChangePas">
              <el-form-item label="确认密码:">
                <el-input
                  v-model="Warehousearray.checkPassword"
                  auto-complete="off"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="关联角色" prop="roleId">
                <el-select
                  multiple
                  v-model="Warehousearray.roleId"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="(item, index) in jueseList"
                    :key="index"
                    :label="item.roleName"
                    :value="item.roleId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="关联员工">
                <el-select
                  v-model="Warehousearray.employeeId"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="(item, index) in yuanGongList"
                    :key="index"
                    :label="item.employeeName"
                    :value="item.employeeId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="queryItem1">
              <el-form-item label="仓库分配:" prop="storageId">
                <el-select
                  v-model="Warehousearray.storageId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in cangkuList"
                    :key="index"
                    :label="item.storageName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div
              class="changePass"
              @click="changePas"
              style="margin: 10px; color: blue"
              v-if="title == '修改'"
            >
              修改密码
            </div>
          </el-form>
        </div>
        <div class="footerbox">
          <el-button class="seniorbut" @click="preservation()">保存</el-button>
        </div>
      </el-dialog>
      <!-- 角色权限弹框 -->
      <el-dialog
        title="分配权限"
        :visible.sync="Popup"
        :before-close="WarehouseClose1"
      >
        <div class="newForm">
          <el-form
            label-width="120px"
            status-icon
            :rules="rules1"
            ref="Warehousearray1"
            :model="PermissionArray"
          >
            <!-- <div class="queryItem1">
              <el-form-item label="PDA权限:" prop="isPDA">
                <el-select v-model="PermissionArray.isPDA">
                  <el-option label="添加" value="1"></el-option>
                  <el-option label="不添加" value="0"></el-option>
                </el-select>
              </el-form-item>
            </div> -->
            <div class="queryItem1">
              <el-form-item label="当前账号:" prop="isPDA">
                <el-input disabled v-model="currentUser"></el-input>
              </el-form-item>
            </div>
            <!-- :disabled="PermissionArray.roleId == 1" -->
            <div class="queryItem1">
              <el-form-item label="角色分配" prop="roleId">
                <el-select multiple v-model="PermissionArray.roleId">
                  <el-option
                    v-for="(item, index) in jueseList"
                    :key="index"
                    :label="item.roleName"
                    :value="item.roleId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="仓库分配:" prop="storageId">
                <el-select
                  multiple
                  v-model="PermissionArray.storageId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in cangkuList"
                    :key="index"
                    :label="item.storageName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="设置默认仓库:" prop="defaultStorageId">
                <el-select
                  v-model="PermissionArray.defaultStorageId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in cangkuList"
                    :key="index"
                    :label="item.storageName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="footerbox">
          <el-button class="seniorbut" @click="SavePermissions()"
            >保存</el-button
          >
        </div>
      </el-dialog>
      <!-- <el-button class="itemBtn btnColor">分配权限</el-button> -->

      <!-- <el-button class="itemBtn btnColor" @click="myModel">列表设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <!-- 查询条件 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">账号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model.trim="queryData.employeeName"
                placeholder="请输入"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">账号类型:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.listType"
                placeholder="请选择"
                clearable
                filterable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in listTypes"
                  :label="item.name"
                  :value="item.val"
                  :key="item.val"
                >
                  {{ item.name }}
                </el-option>
              </el-select>
            </div>
          </div>
          <div
            class="singleinpbtn"
            v-if="hasPermiss('zhqx_zhangHaoGuanLi:query')"
          >
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
          </div>
        </div>
        <div class="item_left">
          <div class="singleinpbtn">
            <!-- <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            > -->
            <!-- <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip> -->
          </div>
        </div>
      </div>
    </el-form>

    <!-- 表格 -->
    <div
      class="tableBox"
      ref="tableWrapper"
      :class="{ tableBox: TableStyle, tableBox1: fullscreen }"
    >
      <!-- <div v-if="fullscreen == false">
        <div class="el-table__common-options">
          <el-dropdown @command="handleCommandTabs">
            <el-button class="righttagICon">
              <i class="el-icon-more"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">表头设置</el-dropdown-item>
              <el-dropdown-item command="b">导出</el-dropdown-item>
              <el-dropdown-item command="c">下载导出模板</el-dropdown-item>
              <el-dropdown-item command="d">保存(上传)模板</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div> -->
      <el-table
        :height="tableHeigth"
        ref="mytable"
        @row-click="rowclick"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            sortable
            :prop="item.field"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
    <!-- //排序 -->
    <div>
      <el-dialog
        title="自定义表头排序"
        :visible.sync="show_sortTableHeard"
        append-to-body
        width="40%"
        center
      >
        <div>
          <h5 style="margin: 0">上下拖动可以排序,需要显示的列请打钩√</h5>
          <div class="titles_dialog">
            <el-checkbox label="全选" v-model="allCheck" @change="checkAll">
            </el-checkbox>
            <div>
              <span>模板名称 : </span>
              <input class="mdTitName" type="text" v-model="sortName" />
            </div>
            <div v-if="tabelHeadeTitle">
              {{ selectLength }} / {{ tabelHeadeTitle.length || 0 }}
            </div>
          </div>
          <draggable v-model="tabelHeadeTitle">
            <transition-group>
              <div
                class="daraStyle"
                v-for="(element, index) in tabelHeadeTitle"
                :key="element.field"
              >
                <el-checkbox
                  class="rowChecks"
                  :label="element.id"
                  v-model="element.isShow"
                  @change="checktab(index, $event)"
                >
                  {{ element.name }}
                </el-checkbox>
                <div class="cloWidth">
                  <div class="cloLabel">列宽:</div>
                  <el-input
                    class="cloInput"
                    type="number"
                    v-model="element.width"
                  ></el-input>
                </div>
                <div class="daraNum">{{ element.sort }}</div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelSortHeard">取 消</el-button>
          <el-button @click="confirmOk" type="primary" style="margin-left: 50px"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 会员信息弹窗 -->
    <div></div>
    <!-- 打包批次号-目的地-渠道代理获取申请打包单信息 -->
    <div>
      <el-dialog
        title="申请打包单信息"
        :visible.sync="dia_sqdb"
        width="40%"
        @close="handleClose_sqdb"
      >
        <div style="margin-bottom: 10px">
          <!--  -->
          <el-input placeholder="请输入打包批次号查询" v-model="daBaoPiCi_s">
            <template slot="append">
              <el-button class="btnColor" @click="daBaoQueryBtn"
                >查询</el-button
              >
            </template>
          </el-input>
        </div>
        <div>
          <el-table
            :data="sqdbData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange_sqdb"
          >
            <el-table-column type="selection" width="60"> </el-table-column>
            <el-table-column prop="nickname" label="会员名称">
            </el-table-column>
            <el-table-column prop="memberId" label="会员号"> </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <paging
            ref="pags"
            :pageNum="pageNum_sqdb"
            :total="total_sqdb"
            :sizeList="sizeList_sqdb"
            :size="size_sqdb"
            @handleSizeChange="handleSizeChange_sqdb"
            @handleCurrentChange="handleCurrentChange_sqdb"
          ></paging>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="dia_sqdb = false">取 消</el-button> -->
          <el-button
            :disabled="selectSqdbData.length == 0"
            type="primary"
            @click="savesqdbBtn"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- 根据拒收状态获取拒收单信息 -->
    <div>
      <el-dialog
        title="拒收单信息"
        :visible.sync="dia_jushou"
        width="40%"
        @close="handleClose_jushou"
      >
        <div>
          <el-table
            :data="juShouInfoData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange_js"
          >
            <el-table-column type="selection" width="60"> </el-table-column>
            <el-table-column prop="courierNumber" label="快递单号">
            </el-table-column>
            <el-table-column prop="handleResult" label="处理结果">
            </el-table-column>
            <el-table-column prop="handleStatus" label="处理状态">
            </el-table-column>
            <el-table-column prop="handleTime" label="处理时间">
            </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <paging
            ref="pags"
            :pageNum="pageNum_js"
            :total="total_js"
            :sizeList="sizeList_js"
            :size="size_js"
            @handleSizeChange="handleSizeChange_js"
            @handleCurrentChange="handleCurrentChange_js"
          ></paging>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dia_jushou = false">取 消</el-button>
          <el-button type="primary" @click="saveJuShouBtn">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <div>
      <el-dialog
        :title="diaTitle_timeType"
        :visible.sync="dia_timeType"
        width="40%"
        @close="handleClose_timeType"
      >
        <div>
          <el-table
            :data="timeTypeData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange_timeType"
          >
            <el-table-column type="selection" width="60"> </el-table-column>
            <el-table-column prop="createTime" label="创建时间">
            </el-table-column>
            <el-table-column
              v-if="timeType == 1"
              prop="number"
              label="入库单编号"
            ></el-table-column>
            <el-table-column
              v-if="timeType == 2"
              prop="number"
              label="出库单编号"
            ></el-table-column>
            <el-table-column
              v-if="timeType == 3"
              prop="number"
              label="打包称重单编号"
            ></el-table-column>
            <el-table-column
              v-if="timeType == 2"
              prop="billStorepackNumber"
              label="仓库打包单编号"
            ></el-table-column>
            <el-table-column
              v-if="timeType == 3"
              prop="channelNumber"
              label="渠道单号"
            ></el-table-column>
            <el-table-column
              v-if="false"
              prop="packageId"
              label="包裹id"
            ></el-table-column>
            <el-table-column
              v-if="false"
              prop="intoStorageId"
              label="入库单id"
            ></el-table-column>
            <el-table-column
              v-if="false"
              prop="outStorageId"
              label="出库单id"
            ></el-table-column>
            <el-table-column
              v-if="false"
              prop="storepackId"
              label="打包单id"
            ></el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <paging
            ref="pags"
            :pageNum="pageNum_timeType"
            :total="total_timeType"
            :sizeList="sizeList_timeType"
            :size="size_timeType"
            @handleSizeChange="handleSizeChange_timeType"
            @handleCurrentChange="handleCurrentChange_timeType"
          ></paging>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dia_timeType = false">取 消</el-button>
          <el-button type="primary" @click="saveTimeTypesBtn">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import screenfull from "screenfull";
import E from "wangeditor";
export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    paging,
  },
  data() {
    return {
      rules: {
        username: [
          {
            required: true,
            message: "请输入账号",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
        ],
        checkPassword: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
        ],
        // roleId: [
        //   {
        //     required: true,
        //     message: "角色不能为空",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        storageId: [
          {
            required: true,
            message: "仓库不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      Warehousearray: {
        username: "", //账号
        password: "", //密码
        checkPassword: "", //确认密码
        employeeId: "", //
        storageId: "", //
        userId: "", //
        roleId: [], //
      },
      time1: "",
      tape: "add",
      countryData: {
        areaContryId: "",
        chineseName: "",
        englishName: "",
        numberCode: "",
      },
      queryData: {
        //
        employeeName: "", //员工姓名
        listType: "admin:list_type", //all:list_type=全部; admin:list_type=管理员; member:list_type=会员账号
      },
      listTypes: [
        {
          name: "全部",
          val: "all:list_type",
        },
        {
          name: "管理员",
          val: "admin:list_type",
        },
        {
          name: "会员账号",
          val: "member:list_type",
        },
      ],
      times: [], //开始结束时间
      timeType: "0", //时间类型
      memberId: "", //会员号
      memberId_s: "", //弹窗输入的会员号
      daBaoPiCi: "", //打包批次号
      daBaoPiCi_s: "", //弹窗打包批次号
      rejectionStatus: "", //拒收状态
      packageIds_js: [], //拒收包裹id,
      packageIds_sqdb: [], //申请打包包裹id,
      packageIds_times: [], //各种时间的包裹id,
      //包裹状态
      packageStatusList: [],
      //拒签状态
      rejectionStatusList: [],
      //验货状态
      inspectionStatusList: [],

      // ================
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "员工账号",
          field: "userName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "角色",
          field: "roleName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "员工编号",
          field: "userId",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        // {
        //   name: "员工编号",
        //   field: "employeeId",
        //   width: "120",
        //   sort: 2,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        {
          name: "员工名称",
          field: "employeeName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "账号状态",
          field: "userStatusShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "所属仓库",
          field: "storageName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "所属部门",
          field: "departmentName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "备注",
          field: "comment",
          width: "120",
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "新建时间",
          field: "createTime",
          width: "180",
          sort: 8,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "申请人",
          field: "creatorUserName",
          width: "120",
          sort: 8,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "修改时间",
          field: "updateTime",
          width: "180",
          sort: 8,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "修改人",
          field: "",
          width: "120",
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "accountManagement", //页面标识
      selectTableData: [], //选中的数据
      length: null,
      //===============

      memberInfoData: [], //会员信息
      pageNum_member: 1, //页码
      total_member: 0,
      sizeList_mb: [10, 20, 50, 100, 200],
      size_member: 10, //一页几条
      selectMemberData: [], //选中的会员信息
      // 根据时间获取不同单的信息
      timeTypeData: [], //会员信息
      pageNum_timeType: 1, //页码
      total_timeType: 0,
      sizeList_timeType: [10, 20, 50, 100, 200],
      size_timeType: 10, //一页几条
      selectdanhaoData: [], //选中的单号信息
      dia_timeType: false,
      diaTitle_timeType: "", //弹窗标题
      // 根据拒收状态获取拒收单的信息
      juShouInfoData: [], //会员信息
      pageNum_js: 1, //页码
      total_js: 0,
      sizeList_js: [10, 20, 50, 100, 200],
      size_js: 10, //一页几条
      selectJSData: [], //选中的单号信息
      dia_jushou: false,
      // 打包批次号-目的地-渠道代理获取申请打包单信息
      sqdbData: [], //会员信息
      pageNum_sqdb: 1, //页码
      total_sqdb: 0,
      sizeList_sqdb: [10, 20, 50, 100, 200],
      size_sqdb: 10, //一页几条
      selectSqdbData: [], //选中的单号信息
      dia_sqdb: false,
      endtime: "",
      sarttime: "",
      jueseList: [], //角色下拉列表
      yuanGongList: [], //角色下拉列表
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      fullscreen: false,
      TableStyle: true,
      NewWarehouse: false, //弹框
      Popup: false, //权限弹框
      //角色分配
      rules1: {
        storageId: [
          { required: true, message: "请至少分配一个仓库", trigger: "change" },
        ],
        // roleId: [{ required: true, message: "请分配角色", trigger: "change" }],
        defaultStorageId: [
          { required: true, message: "请分配仓库", trigger: "change" },
        ],
      },
      currentUser: "",
      //权限数组
      PermissionArray: {
        employeeId: "", //员工id
        isPDA: "0", //是否添加pda权限(0=否,1=是)
        storageId: [], //仓库id数组
        roleId: [], //角色id
        userId: "", //用户id
        defaultStorageId: "", //
      },
      title: "", //弹框标题
      bumenList: [], //部门下拉框
      cangkuList: [], //仓库下拉框
      isChangePas: false,
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.myTableHeard = this.tabelHeadeTitle;
    // this.getGroupFindFieldSort(); //获取用户自定义表头

    this.endtime = tools.getCurrentDate(); //今天的时间;
    // this.endtime = tools.getFlexDate(1); //今天+1的时间;
    this.sarttime = tools.getFlexDate(-3); //三天前
    this.times = [this.sarttime, this.endtime];
    this.getlist();
    this.getroleList();

    this.getData();
  },
  mounted() {
    const that = this;

    // window.onresize = () => {
    //   //屏幕发生变化时重新计算(有bug,高度变化还是原来那个高度)
    //   this.clientHeight = document.body.clientHeight;
    //   return (() => {
    //     that.fetTableHeight();
    //   })();
    // };
  },
  watch: {},
  methods: {
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    //分配权限保存
    SavePermissions() {
      this.$refs.Warehousearray1.validate((vali) => {
        if (vali) {
          let PermissionArray = this.PermissionArray;
          let dfid = PermissionArray.defaultStorageId;
          let storageArr = [];
          let storageIds = PermissionArray.storageId || [];
          storageIds.forEach((item, index) => {
            let objs = {
              isDefault: item == dfid ? 1 : 0,
              storageId: item,
            };
            storageArr.push(objs);
          });

          let param = {
            employeeId: PermissionArray.employeeId || "", //员工id
            isPDA: PermissionArray.isPDA || "0", //是否添加pda权限(0=否,1=是)
            storageId: storageArr || [], //仓库id数组
            roleIds: PermissionArray.roleId || [], //角色id
            userId: PermissionArray.userId || "", //用户id
          };
          let sign = tools.getSign(param);
          param.sign = sign;
          Api.adminUserRelatedRole(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success("关联成功");
              setTimeout(() => {
                this.WarehouseClose1();
                this.getData();
              }, 500);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 修改密码
    changePas() {
      this.isChangePas = true;
    },
    //保存
    preservation() {
      let Warehousearray = this.Warehousearray;
      this.$refs.Warehousearray.validate((vail) => {
        if (vail) {
          if (Warehousearray.checkPassword !== Warehousearray.password) {
            this.$message.error("两次密码不一致");
            return;
          }
          if (this.title == "新建") {
            let param = {
              username: Warehousearray.username || "", //账号
              password: Warehousearray.password || "", //密码
              checkPassword: Warehousearray.checkPassword || "", //确认密码
              employeeId: Warehousearray.employeeId || "", //
              storageId: Warehousearray.storageId || "", //
              roleId: Warehousearray.roleId || [], //
            };
            let sign = tools.getSign(param);
            param.sign = sign;
            Api.addUser(param).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                setTimeout(() => {
                  this.WarehouseClose();
                  this.getData();
                }, 500);
              }
            });
          } else {
            let param1 = {
              // username: Warehousearray.username || "", //账号
              password: Warehousearray.password || "", //密码
              checkPassword: Warehousearray.checkPassword || "", //确认密码
              employeeId: Warehousearray.employeeId || "", //
              storageId: Warehousearray.storageId || "", //
              userId: Warehousearray.userId || "", //
              roleId: Warehousearray.roleId || [], //
            };
            let sign = tools.getSign(param1);
            param1.sign = sign;
            Api.updateUser(param1).then((res) => {
              if (res.data.status == "success") {
                this.$message.success("修改成功");
                setTimeout(() => {
                  this.WarehouseClose();
                  this.getData();
                }, 500);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    //重置权限弹窗
    WarehouseClose1() {
      this.$refs.Warehousearray1.resetFields();
      this.PermissionArray = {
        employeeId: "", //员工id
        isPDA: "0", //是否添加pda权限(0=否,1=是)
        storageId: [], //仓库id数组
        roleId: [], //角色id
        userId: "", //用户id
      };
      this.Popup = false;
    },
    //重置
    WarehouseClose() {
      // this.$refs.Warehousearray.resetFields();
      this.Warehousearray = {
        username: "", //账号
        password: "", //密码
        checkPassword: "", //确认密码
        employeeId: "", //
        storageId: "", //
        roleId: [], //
      };
      this.NewWarehouse = false;
      this.isChangePas = false;
    },
    //新增
    addMember() {
      this.NewWarehouse = true;
      this.employeeChooseList();
      this.title = "新建";
    },
    //修改
    modifybut() {
      this.employeeChooseList();
      let nid;
      let selectTableData = this.selectTableData;
      if (selectTableData.length == 1) {
        nid = selectTableData[0].userId;
        this.NewWarehouse = true;
        this.title = "修改";
        this.userDetail(nid);
      } else {
        this.$message.warning("请勾选一个账号");
      }
    },
    // 分配权限
    jurisdiction_qx() {
      let selectTableData = this.selectTableData;
      let nid;
      if (selectTableData.length != 1) {
        this.$message.warning("请勾选一条数据");
      } else {
        //console.log(selectTableData[0].roleId)
        nid = selectTableData[0].userId;
        let userName = selectTableData[0].userName;
        this.$router.push(
          "/systemManagement/AccountAuthority/jurisdictionUser?nid=" +
            nid +
            "&userName=" +
            userName
        );
      }
    },
    //角色分配
    jurisdiction() {
      let selectTableData = this.selectTableData;
      if (selectTableData.length == 1) {
        Api.getRelatedRole({
          adminId: selectTableData[0].userId,
        }).then((res) => {
          if (res.data.status == "success") {
            this.Popup = true;
            let stotageInfo = res.data.result.stotageInfo || [];
            let roleInfo = res.data.result.roleInfo || [];
            this.currentUser = selectTableData[0].userName;
            this.PermissionArray.userId = selectTableData[0].userId;
            this.PermissionArray.employeeId = selectTableData[0].employeeId;

            if (stotageInfo.length > 0) {
              this.PermissionArray.storageId = [stotageInfo[0].storageId];
              stotageInfo.forEach((item) => {
                if (item.storageId == 1) {
                  this.PermissionArray.defaultStorageId = item.storageId;
                }
              });
            }
            if (roleInfo.length > 0) {
              // let ids = [];
              // let ids = '';
              // roleInfo.forEach((item) => {
              //   ids.push(item.roleId);
              // });
              // this.PermissionArray.roleId = roleInfo[0].roleId;
              let arrs = [];
              roleInfo.forEach((item) => {
                arrs.push(item.roleId);
              });
              this.PermissionArray.roleId = arrs;
            }
          }
        });
      } else {
        this.$message.warning("请勾选一条数据");
      }
    },
    //获取角色下拉列表
    getroleList() {
      Api.roleList().then((res) => {
        this.jueseList = res.data.result.data || [];
      });
    },
    //获取员工下拉列表
    employeeChooseList() {
      Api.employeeChooseList({ employeeName: "" }).then((res) => {
        this.yuanGongList = res.data.result || [];
      });
    },
    //详情
    userDetail(nid) {
      Api.userDetail({ userId: nid }).then((res) => {
        //console.log(res.data.result)
        let Warehousearray = res.data.result || [];
        let ids = [];
        if (Warehousearray.roleInfo && Warehousearray.roleInfo.length > 0) {
          Warehousearray.roleInfo.forEach((item) => {
            ids.push(item.roleId);
          });
        }
        this.Warehousearray = {
          username: Warehousearray.username, //账号
          password: "", //密码
          checkPassword: "", //确认密码
          userId: Warehousearray.userId,
          // userStatus: Warehousearray.userStatus,
          employeeId: Warehousearray.employeeId,
          employeeName: Warehousearray.employeeName,
          storageId: Warehousearray.storageId,
          storageName: Warehousearray.storageName,
          roleId: ids,
        };
      });
    },
    //停用启用
    Enable(num, e) {
      let selectTableData = this.selectTableData;
      let param = new Object();
      let nidlist = new Array();
      if (selectTableData.length >= 1) {
        this.$confirm("是否确认" + e + "账号?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          selectTableData.forEach((item) => {
            nidlist.push(item.userId);
          });
          param.userId = nidlist;
          param.isEnable = num;
          let sign = tools.getSign(param);
          param.sign = sign;
          Api.enableOrDisable(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(e + "成功");
              this.getData();
            }
          });
        });
      } else {
        this.$message.warning("请至少勾选一条数据");
      }
    },
    //获取下拉框
    getlist() {
      //获取仓库下拉列表
      Api.getStorageList().then((res) => {
        this.cangkuList = res.data.result || [];
      });
      Api.departmentChooseList().then((res) => {
        if (res.data.status == "success") {
          this.bumenList = res.data.result;
        }
      });
    },

    //新建国家
    confirmbut() {
      let countryData = this.countryData;

      let parm = {
        areaContryId: countryData.areaContryId,
        chineseName: countryData.chineseName,
        englishName: countryData.englishName,
        numberCode: countryData.numberCode,
      };
      let signarr = tools.getSign(parm);
      countryData.sign = signarr;
      Api.addOrUpdateAreaContry(countryData).then((res) => {});
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },

    handleCommandTabs(m) {
      if (m == "a") {
        this.myModel();
      } else if (m == "b") {
        this.exportBtn();
      } else if (m == "c") {
        this.exportTemplateDownload();
      } else if (m == "d") {
        this.upLoadBtn();
      } else {
        this.screen();
      }
    },

    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },

    // 输入批次号,回车
    getDaBaoDan(e) {
      // if (this.daBaoPiCi) {
      this.dia_sqdb = true;
    },
    // 打包批次号-目的地-渠道代理获取申请打包单信息
    getPackPackageBillForPackage() {
      let pids = this.daBaoPiCi_s || "";
      if (pids) {
        pids = pids.split(",");
      }
      let param = {
        areaCountryId: "", //国家id
        channelId: "", //渠道id
        number: JSON.stringify(pids), //打包批次号
        pageStart: this.pageNum_sqdb,
        pageTotal: this.size_sqdb,
      };
      Api.getPackPackageBillForPackage(param).then((res) => {
        this.sqdbData = res.data.result.data || [];
        this.size_sqdb = res.data.result.pageTotal || 0;
        this.total_sqdb = res.data.result.pageCount || 0;
      });
    },

    // 输入会员号,回车

    // 根据会员号获取会员信息
    getMemberByMemberId() {
      let mids = this.memberId_s;
      // if (mids) {
      //     mids = mids.split(',')
      // }
      let param = {
        memberId: mids,
        pageStart: this.pageNum_member,
        pageTotal: this.size_member,
      };
      Api.getMemberByMemberId(param).then((res) => {
        this.memberInfoData = res.data.result.data || [];
        this.size_member = res.data.result.pageTotal || 0;
        this.total_member = res.data.result.pageCount || 0;
        this.queryData.userIds = [];
      });
    },
    // 选择拒收状态
    changeRejectStatus(e) {
      this.rejectionStatus = e;
      this.getRejectionBillForPackage();
      this.dia_jushou = true;
    },

    // 根据拒收状态获取拒收单信息
    getRejectionBillForPackage() {
      let param = {
        rejectionStatus: this.rejectionStatus,
        pageStart: this.pageNum_member,
        pageTotal: this.size_member,
      };
      Api.getRejectionBillForPackage(param).then((res) => {
        this.memberInfoData = res.data.result.data || [];
        this.size_member = res.data.result.pageTotal || 0;
        this.total_member = res.data.result.pageCount || 0;
      });
    },
    //关闭会员信息弹窗
    handleClose_member() {
      this.memberInfoData = [];
      this.selectMemberData = [];
    },
    //关闭拒收单信息弹窗
    handleClose_jushou() {
      this.juShouInfoData = [];
      this.selectJSData = [];
    },
    //关闭申请打包信息弹窗
    handleClose_sqdb() {
      this.sqdbData = [];
      this.selectSqdbData = [];
      this.dia_sqdb = false;
    },

    //输入批次号,查询
    daBaoQueryBtn() {
      this.getPackPackageBillForPackage();
    },
    //拒收单==>确定按钮
    saveJuShouBtn() {
      let arr = [];
      for (var i = 0; i < this.selectJSData.length; i++) {
        arr.push(this.selectJSData[i].packageId);
      }
      this.packageIds_js = arr;
      this.dia_jushou = false;
    },
    //申请打包信息==>确定按钮
    savesqdbBtn() {
      let arr = [];
      for (var i = 0; i < this.selectJSData.length; i++) {
        arr.push(this.selectJSData[i].packageId);
      }
      this.packageIds_sqdb = arr;
      this.dia_jushou = false;
    },
    //会员信息==>选择表格操作
    handleSelectionChange_menber(e) {
      this.selectMemberData = e;
    },
    //拒收单信息==>选择表格操作
    handleSelectionChange_js(e) {
      this.selectJSData = e;
    },
    //申请打包==>选择表格操作
    handleSelectionChange_sqdb(e) {
      this.selectSqdbData = e;
    },
    //选择表格操作
    handleSelectionChange_timeType(e) {
      this.selectdanhaoData = e;
      //console.log(e);
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e;
      this.length = e.length;
    },

    //关闭时间单号信息弹窗
    handleClose_timeType() {
      this.timeTypeData = [];
      this.selectdanhaoData = [];
    },
    //根据时间xxx==>确定按钮
    saveTimeTypesBtn() {
      let arr = [];
      for (var i = 0; i < this.selectdanhaoData.length; i++) {
        arr.push(this.selectdanhaoData[i].packageId);
      }
      this.packageIds_times = arr;
      this.dia_timeType = false;
    },

    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */

    /**
     * =========================按钮部分的方法=>结束??????????????????????????????????
     */

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);

      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = downloadurl;
        a.target = "_blank";
        a.setAttribute("download", "综合查询.xlsx"); //指定下载后的文件名，防跳转
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "角色管理列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        //console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth = 50;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60; //60是顶部导航
      return Math.floor(windonHeight - otherHeight) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法 
    },
    // 获取数据
    getData() {
      let formData = this.queryData;
      let that = this;
      formData.pageStart = that.pageNum;
      formData.pageTotal = that.size;
      Api.adminUserList(formData).then((res) => {
        //console.log(111, res.data.result.data);
        if (res.data.status == "success") {
          that.className = res.data.result.className || "";
          that.tableData = res.data.result.data || [];
          that.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        employeeName: "", //员工姓名
      };
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },

    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk() {
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //综合查询接口路径
        },
        {
          key: "groupName",
          val: this.pageGroupName,
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    //分页(会员信息的)
    handleSizeChange_mb(val) {
      this.size = val;
      this.getMemberByMemberId();
    },
    handleCurrentChange_mb(val) {
      this.pageNum = val;
      this.getMemberByMemberId();
    },
    //分页(拒收单信息的)
    handleSizeChange_js(val) {
      this.size = val;
      this.getRejectionBillForPackage();
    },
    handleCurrentChange_js(val) {
      this.pageNum = val;
      this.getRejectionBillForPackage();
    },
    //分页(申请打包单信息的)
    handleSizeChange_sqdb(val) {
      this.size = val;
      this.getPackPackageBillForPackage();
    },
    handleCurrentChange_sqdb(val) {
      this.pageNum = val;
      this.getPackPackageBillForPackage();
    },
    //分页(时间类型单信息的)
    handleSizeChange_timeType(val) {
      this.size = val;
      this.getMemberByMemberId();
    },
    handleCurrentChange_timeType(val) {
      this.pageNum = val;
      this.getMemberByMemberId();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}

.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.newForm {
  margin-left: 80px;
  .queryItem1 {
    width: 60%;
    margin-top: 20px;
  }
}
.changePass {
  cursor: pointer;
}
</style>
